import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';

import App from './App.tsx';
import { GA_CONFIG } from './constants';

import './tailwind.css';

const hostname = window.location.hostname;
const googleConfig = GA_CONFIG.find((config) => config.hostname === hostname);

if (googleConfig) {
  console.log('Google Analytics should be initialised');
  ReactGA.initialize(googleConfig.googleId);
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
