import { useContext } from 'react';
import * as React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { AppConfig } from '../../config';

const cache = new InMemoryCache();

interface IProps {
  children?: React.ReactChild | React.ReactChild[];
}
export const ApolloConfigProvider = ({ children }: IProps) => {
  const { apiUrl, userInfo } = useContext(AppConfig);

  const httpLink = createHttpLink({
    uri: apiUrl,
    credentials: 'include',
  });

  const authLink = setContext((_, { headers }) => {
    const token = userInfo ? userInfo.token : '';

    return {
      headers: {
        ...headers,
        Authorization: token,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
