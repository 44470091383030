export const PAGE_SIZE = 10;

export const COGNITO_LOGIN_URL = `https://${import.meta.env.VITE_APP_COGNITO_CLIENT_NAME}.auth.${import.meta.env.VITE_APP_COGNITO_REGION}.amazoncognito.com/oauth2/authorize?response_type=code&client_id=${import.meta.env.VITE_APP_COGNITO_CLIENT_ID}&redirect_uri=${import.meta.env.VITE_APP_COGNITO_REDIRECT_URL}&scope=email+openid+profile`;

export const FEEDBACK_THRESHOLD = 4;

export const SORT_ORDER_MAP = {
  ascend: 'asc',
  descend: 'desc',
};

export const DESKTOP_WIDTH = 1200;

export const GA_CONFIG = [
  {
    hostname: 'helix.thomsonreuters.com',
    googleId: 'G-5HM7WNBR6E',
  },
];

export const GA_HOSTNAMES = GA_CONFIG.map((config) => config.hostname);

export const TAG_RANKING = {
  geographicDomain: 1,
  contentDomain: 2,
  taskDomain: 3,
  methodDomain: 4,
  technologyDomain: 5,
  productDomain: 6,
  tags: 7,
};

export const DOMAIN_NAME_MAPPING = {
  geographicDomain: 'Geography',
  contentDomain: 'Content',
  taskDomain: 'Tasks',
  methodDomain: 'Methods',
  technologyDomain: 'Technology',
  productDomain: 'Products',
  tags: 'Tags',
};

export type Domains = keyof typeof DOMAIN_NAME_MAPPING;
