import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';

import { AppConfigProvider, ApolloConfigProvider } from './config';
import { usePageTracking } from './hooks';
import { AppFooter } from './components/AppFooter/AppFooter';
import { AppHeader } from './components/AppHeader/AppHeader';
import { SilentRefresh } from './components/SilentRefresh/SilentRefresh';

import styles from './App.module.css';

// Lazy Imports
const AuthPage = lazy(() => import('./routes/AuthPage/AuthPage'));
const UserRoute = lazy(() => import('./routes/helpers/UserRoute'));
const AboutPage = lazy(() => import('./routes/AboutPage/AboutPage'));
const SupportPage = lazy(() => import('./routes/SupportPage/SupportPage'));
const LandingPage = lazy(() => import('./routes/LandingPage/LandingPage'));
const RepoPage = lazy(() => import('./routes/RepoPage/RepoPage'));
const AdminRoute = lazy(() => import('./routes/helpers/AdminRoute'));
const AdminPage = lazy(() => import('./routes/AdminPage/AdminPage'));
const NoMatch = lazy(() => import('./routes/NoMatch/NoMatch'));
const MyFavouritesPage = lazy(() => import('./routes/MyFavouritesPage/MyFavouritesPage'));
const MyReposPage = lazy(() => import('./routes/MyReposPage/MyReposPage'));
const CrawlerMetadata = lazy(() => import('./routes/CrawlerMetadata/CrawlerMetadata'));
const CrawlerRunDetail = lazy(() => import('./routes/CrawlerRunDetail/CrawlerRunDetail'));
const CredentialsPage = lazy(() => import('./routes/CredentialsPage/CredentialsPage'));
const InnerSourcePage = lazy(() => import('./routes/LandingPage/InnerSourcePage'));

const defaultThemeData = {
  colorPrimary: '#FF8000',
  colorInfo: '#FF8000',
};

const RouterApp = () => {
  usePageTracking();
  return (
    <div className="flex flex-col h-full">
      <AppHeader />
      <Switch>
        <Route exact path="/auth" component={AuthPage} />
        <Route exact path="/about" component={AboutPage} />
        <UserRoute exact path="/support" renderComponent={SupportPage} />
        <UserRoute exact path="/" renderComponent={LandingPage} />
        <UserRoute exact path="/innerSource" renderComponent={InnerSourcePage} />
        <UserRoute path="/repos" renderComponent={RepoPage} />
        <AdminRoute exact path="/users" renderComponent={AdminPage} />
        <AdminRoute exact path="/credentials" renderComponent={CredentialsPage} />
        <UserRoute exact path="/favourites" renderComponent={MyFavouritesPage} />
        <UserRoute exact path="/myrepos" renderComponent={MyReposPage} />
        <UserRoute exact path="/crawler" renderComponent={CrawlerMetadata} />
        <UserRoute path="/crawler/:timestamp" renderComponent={CrawlerRunDetail} />
        <Route path="*" component={NoMatch} />
      </Switch>
      <AppFooter />
    </div>
  );
};

function App() {
  return (
    <AppConfigProvider>
      <ApolloConfigProvider>
        <SilentRefresh>
          <BrowserRouter>
            <ConfigProvider theme={{ token: defaultThemeData }}>
              <Suspense
                fallback={
                  <div className={`flex items-center justify-center ${styles.container}`}>
                    <Spin size="large" />
                  </div>
                }>
                <RouterApp />
              </Suspense>
            </ConfigProvider>
          </BrowserRouter>
        </SilentRefresh>
      </ApolloConfigProvider>
    </AppConfigProvider>
  );
}

export default App;
