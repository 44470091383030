export enum RoleAccessType {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum SortOrder {
  DESCEND = 'desc',
  ASCEND = 'asc',
}

export enum SortTerm {
  SCORE = 'SCORE',
  DATEINITIALIZED = 'DATEINITIALIZED',
  LASTPUSHEDAT = 'LASTPUSHEDAT',
  MOSTFAVOURITED = 'MOSTFAVOURITED',
  REPOSCORE = 'REPOSCORE',
}

export enum CardTypes {
  app = 'app',
  capability = 'capability',
  container = 'container',
  dataset = 'dataset',
  framework = 'framework',
  library = 'library',
  model = 'model',
  project = 'project',
  service = 'service',
  template = 'template',
}

export enum Relationships {
  FAVOURITE = 'FAVOURITE',
}
