export const AppFooter = () => {
  const footer = (
    <div className="flex flex-row justify-center gap-x-12">
      <div className="pb-1">Designed and Implemented at Thomson Reuters Labs</div>
    </div>
  );

  return (
    <footer
      className="border-t border-gray-400 pt-2.5 pb-1"
      style={{
        borderTopWidth: '1px',
        background:
          'linear-gradient(165deg, rgba(175,175,175,0.05) 0%,rgba(122,122,122,0.05) 48%,rgba(64,64,64,0.06) 100%)',
      }}>
      {footer}
    </footer>
  );
};
