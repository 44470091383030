import { Fragment, ReactElement, useContext, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Row, Col, Button, Modal } from 'antd';

import { useInterval } from '../../hooks';
import { AppConfig } from '../../config';
import { COGNITO_LOGIN_URL } from '../../constants';

const REFRESH = gql`
  mutation Refresh {
    refresh {
      idToken
      user {
        email
        name
        role
      }
    }
  }
`;

interface I_Props {
  children: ReactElement;
}

export function SilentRefresh({ children }: I_Props) {
  const { updateUserInfo } = useContext(AppConfig);
  const [showModal, setShowModal] = useState(false);

  const [refresh] = useMutation(REFRESH, {
    onCompleted: (data) => {
      if (data.refresh.idToken) {
        updateUserInfo(data.refresh.idToken, data.refresh.user);
      } else {
        // Save path we want to redirect to
        localStorage.setItem('path', window.location.pathname);
        setShowModal(true);
      }
    },
  });

  useInterval(
    () => {
      if (!showModal) {
        refresh();
      }
    },
    1000 * 60 * 55
  ); // Refresh token every 55 minutes (JWT is valid for 1 hour)

  return (
    <Fragment>
      <Modal title="Logged Out" open={showModal} onCancel={() => setShowModal(false)} footer={null}>
        <Row justify="center" align="middle">
          <Col>
            <Button href={COGNITO_LOGIN_URL} size="large">
              Login
            </Button>
          </Col>
        </Row>
      </Modal>
      {children}
    </Fragment>
  );
}
