import { useContext, useEffect, useRef, useState } from 'react';
import logo from '../../assets/helix-logo.jpeg';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { AppConfig } from '../../config';
import { RoleAccessType } from '../../enums';
import { DESKTOP_WIDTH } from '../../constants';
import { AuthButton } from '../AuthButton/AuthButton';

export const AppHeader = () => {
  const location = useLocation();
  const { userInfo } = useContext(AppConfig);
  const isLoggedIn = userInfo && !!userInfo.token;
  const roleMatches = [RoleAccessType.ADMIN as string].includes(userInfo?.user?.role || '');

  const [currentMenuKey, setCurrentMenuKey] = useState('home');

  const [pageWidth, setPageWidth] = useState(0);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const divEl = divRef.current;
    if (!divEl) return;
    const resizeListener = () => {
      if (divEl) {
        setPageWidth(divEl.clientWidth);
      }
    };
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/support')) {
      setCurrentMenuKey('support');
    } else if (location.pathname.includes('innerSource')) {
      setCurrentMenuKey('innersource');
    } else if (location.pathname.includes('/about')) {
      setCurrentMenuKey('about');
    } else if (location.pathname.includes('/users')) {
      setCurrentMenuKey('users');
    } else if (location.pathname.includes('/crawler')) {
      setCurrentMenuKey('crawler');
    } else if (location.pathname.includes('/me')) {
      setCurrentMenuKey('me');
    } else {
      setCurrentMenuKey('home');
    }
  }, [location]);

  const nameMenuWidth = (userInfo?.user?.name || 'Default User').length * 10 + 30;

  const items: MenuProps['items'] = [
    {
      label: <Link to="/innerSource">InnerSource</Link>,
      key: 'innersource',
    },
    {
      label: <Link to="/crawler">Crawler</Link>,
      key: 'crawler',
    },
    {
      label: (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://helix.thomsonreuters.com/static-sites/site-builds/labs-helix_user-docs/user-docs/">
          Documentation
        </a>
      ),
      key: 'documentation',
    },
    {
      label: <Link to="/support">Support</Link>,
      key: 'support',
    },
    {
      label: <Link to="/about">About</Link>,
      key: 'about',
    },
    {
      label: <AuthButton />,
      key: 'me',
    },
  ];

  if (isLoggedIn && roleMatches) {
    items.splice(3, 0, {
      label: <Link to="/users">Users</Link>,
      key: 'users',
    });
  }

  return (
    <div
      ref={divRef}
      className="w-full bg-white border-gray-400"
      style={{
        borderBottomWidth: '1px',
        boxShadow: '0px 0px 10px -6px rgba(0,0,0,0.8)',
      }}>
      <div className="flex flex-row items-center border-b w-full mx-auto">
        <Link className="px-3" to="/">
          <div
            className="rounded-full border border-gray-400 ml-2 p-1 bg-white"
            style={{
              boxShadow: '0px 0px 10px -6px rgba(0,0,0,0.3)',
            }}>
            <img
              style={{
                height: '2rem',
                width: '2rem',
                padding: '.2rem',
              }}
              src={logo}
              alt="logo"
            />
          </div>
        </Link>
        <div>
          <div className="text-lg font-bold text-gray-900" style={{ marginBottom: '-5px' }}>
            Helix
          </div>
          <div className="text-s text-gray-700">Search and Discovery</div>
        </div>
        {pageWidth > DESKTOP_WIDTH ? (
          <Menu
            onClick={(event) => setCurrentMenuKey(event.key as string)}
            selectedKeys={[currentMenuKey]}
            mode="horizontal"
            style={{
              marginLeft: 'auto',
              marginRight: '10px',
              marginBottom: '0px',
              lineHeight: '60px',
              paddingRight: '1rem',
              width: isLoggedIn && roleMatches ? `${600 + nameMenuWidth}px` : `${525 + nameMenuWidth}px`,
            }}
            items={items}
          />
        ) : (
          <Menu
            onClick={(event) => setCurrentMenuKey(event.key as string)}
            selectedKeys={[currentMenuKey]}
            mode="horizontal"
            style={{
              marginLeft: '20px',
              width: `${pageWidth - 250}px`,
            }}
            items={items}
          />
        )}
      </div>
    </div>
  );
};
