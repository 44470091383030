import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { GA_HOSTNAMES } from '../constants';

export function usePageTracking() {
  const location = useLocation();
  useEffect(() => {
    const shouldTrack = GA_HOSTNAMES.includes(window.location.hostname);
    if (shouldTrack) {
      ReactGA.send({
        hitType: 'pageview',
        page: `${location.pathname}${location.search}`,
      });
    }
  }, [location]);
}
