import * as React from 'react';

export interface globalThis extends Window {}

const HOST_LOCALHOST = 'localhost';
const LOCAL_API_PORT = '4000';

const hostname = globalThis.location.hostname;

const API_URL = hostname.includes(HOST_LOCALHOST)
  ? `${globalThis.location.protocol}//${hostname}:${LOCAL_API_PORT}/graphql`
  : `${globalThis.location.origin}/graphql`;

const environment = hostname.includes('preprod') || hostname.includes('localhost') ? 'DEVELOPMENT' : 'PRODUCTION';

interface IUser {
  email: string;
  name: string;
  role: string;
}

interface IUserInfo {
  token?: string;
  user?: IUser;
}

interface IAppConfig {
  apiUrl: string;
  userInfo?: IUserInfo;
  updateUserInfo: (token?: string, user?: IUser) => void;
  environment: string;
}

export const AppConfig = React.createContext<IAppConfig>({
  apiUrl: API_URL,
  environment,
  updateUserInfo: () => {},
});

interface IProps {
  children?: React.ReactNode;
}

export const AppConfigProvider: React.FC<IProps> = ({ children }) => {
  const [userInfo, setUserInfo] = React.useState<IUserInfo>();

  const updateUserInfo = (token?: string, user?: IUser) => {
    setUserInfo({
      token,
      user,
    });
  };

  return (
    <AppConfig.Provider
      value={{
        apiUrl: API_URL,
        userInfo,
        updateUserInfo,
        environment,
      }}>
      {children}
    </AppConfig.Provider>
  );
};
