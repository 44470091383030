import { Fragment, useContext, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { Link } from 'react-router-dom';

import { AppConfig } from '../../config';
import { COGNITO_LOGIN_URL } from '../../constants';
import { RoleAccessType } from '../../enums';

export const AuthButton = () => {
  const { userInfo } = useContext(AppConfig);
  const [open, setOpen] = useState(false);

  const isLoggedIn = userInfo ? !!userInfo.user : false;

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const UserMenu = (
    <ul>
      <li className="my-1">
        <Button type="text">
          <Link to="/favourites">My Favourites</Link>
        </Button>
      </li>
      <li className="my-1">
        <Button type="text">
          <Link to="/myrepos">My Repos</Link>
        </Button>
      </li>
      {userInfo?.user?.role === RoleAccessType.ADMIN && (
        <li className="my-1">
          <Button type="text">
            <Link to="/credentials">My Credentials</Link>
          </Button>
        </li>
      )}
    </ul>
  );

  return (
    <Fragment>
      {isLoggedIn ? (
        <Popover content={UserMenu} trigger="click" open={open} onOpenChange={handleOpenChange}>
          <Button style={{ border: 'none', padding: '0px' }} type="text" icon={<UserOutlined />}>
            <span className="ml-2">{userInfo?.user?.name}</span>
          </Button>
        </Popover>
      ) : (
        <Button style={{ border: 'none', padding: '0px' }} type="link" href={COGNITO_LOGIN_URL}>
          Login
        </Button>
      )}
    </Fragment>
  );
};
